<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-20 10:55:04
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-24 15:27:01
 * @FilePath: /mediatom-web/src/views/aggregate/Config/components/sourceModal/JDAutoDrawForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="isAutoDraw-form-container">
    <!-- 是否自渲染 -->
    <!-- 其他 -->
    <a-form-model-item
      prop="isAutoDrawing"
      v-if="showIsAutoDrawing(formquery, positionId, formquery.platId)"
    >
      <m-tips
        slot="label"
        :content="`开启自渲染，您需要在对应的第三方广告平台创建“信息流类型下的原生自渲染”广告位或代码位，${title}SDK将会把返回的广告信息封装成该广告位的样式进行渲染。`"
        :title="'是否自渲染'"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.isAutoDrawing" @change="changeAutoDraw">
        <a-radio value="1"> 是 </a-radio>
        <a-radio value="0"> 否 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 京东 开屏 -->
    <a-form-model-item
      prop="isAutoDrawing"
      v-if="+platId === 4 && +positionId === 1"
    >
      <m-tips
        slot="label"
        :content="`京东渲染、媒体渲染按京媒后台选项匹配平台选项。平台渲染：平台使用京媒信息流样式渲染成开屏样式。`"
        :title="'渲染样式'"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.isAutoDrawing" @change="changeAutoDraw">
        <a-radio value="0"> 京东渲染 </a-radio>
        <a-radio value="2"> 媒体渲染 </a-radio>
        <a-radio value="1"> 平台渲染 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 京东 插屏 -->
    <a-form-model-item
      prop="isAutoDrawing"
      v-if="+platId === 4 && +positionId === 3"
    >
      <m-tips
        slot="label"
        :content="`渲染类型按京媒后台广告场景字段选项匹配平台选项。`"
        :title="'渲染类型'"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.isAutoDrawing" @change="changeAutoDraw">
        <a-radio value="1"> 信息流 </a-radio>
        <a-radio value="2"> 插屏 </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </div>
</template>

<script>
import { showIsAutoDrawing } from '@/utils/autoLabel'
import moment from 'moment'
import mixDate from '@/mixins/initDate'
import { mapState } from 'vuex'
export default {
  name: 'AdxFlowForm',
  mixins: [mixDate],
  data () {
    return {}
  },
  created () {
    this.$set(this.formquery, 'isAutoDrawing', this.value.isAutoDrawing || '0')
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title
    })
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    platId: {
      default: undefined,
      type: [String, Number]
    },
    positionId: {
      default: undefined,
      type: [String, Number]
    }
  },
  methods: {
    moment,
    showIsAutoDrawing,
    changeAutoDraw (e) {
      this.$set(this.formquery, 'closeButtonType', 'A')
    }
  }
}
</script>

<style lang="less" scoped>
// .isAutoDraw-form-container{
//   border: 1px solid;
// }
</style>
